.App {
    text-align: left;
    margin:0 auto;
    max-width: 1920px;
}

html {
    background-color: #222426;
    min-height: 100vh;
    scroll-behavior: smooth;
}

@-moz-document url-prefix() {
    html {
        scroll-behavior: auto;
    }
    body {
        scroll-behavior: smooth;
    }
}

body {
    font-family: "Open Sans";
    overflow-y: scroll;
    -webkit-transform: rotate3d(0,0,0,0deg);
    -moz-transform: rotate3d(0,0,0,0deg);
    -ms-transform: rotate3d(0,0,0,0deg);
    -o-transform: rotate3d(0,0,0,0deg);
    transform: rotate3d(0,0,0,0deg);
}

input {
    font-family: "Open Sans";
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

a, a:visited {
    color: #0ac;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.section {
    background: #09c;
    background: #3bd;
    background: linear-gradient(#3bd,#3ce);
    color: #333;
}

.section-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.section-alt {
    background: #222426;
    color: #e0f0e0;
}

.section-alt2 {
    background: #424448;
    color: #e0f0e0;
}

.section-menu {
    background:rgba(34,100,134,.9);
    color: #ffe;
}

.section .content {
    padding: 40px;
}

.section .content-left {
    text-align: left;

}

.col-2, .col-3, .col-9, .col-4, .col-8, .col-6, col-10 {
    display: inline-block;
    vertical-align: top;
}

.section .col-2, .section .col-3, .section .col-4, .section .col-6, .section .col-8, .section .col-9, .section .col-10, .section .col12 {
    //top: 50%;
    //transform: translateY(-50%);
    //text-align:center;
    //position: relative;
    //vertical-align: middle;
}

.col-2 {
    width: 16.66666%;
    min-width: 100px;
    max-width: 100%;
}

.col-3 {
    width: 25%;
    min-width: 150px;
    max-width: 100%;
}

.col-4 {
    width: 33.33333%;
    min-width: 200px;
    max-width: 100%;
}

.col-6 {
    width: 50%;
    min-width: 250px;
    max-width: 100%;
}

.col-6-sm {
    max-width: 400px;
    min-width: 200px;
}

.col-8 {
    width: 66.66667%;
    min-width: 300px;
    max-width: 100%;
}

.col-9 {
    width: 75%;
    min-width: 450px;
    max-width: 100%;
}

.col-10 {
    width: 83.33333%;
    min-width: 500px;
    max-width: 100%;
}

.col-12 {
    width: 100%;
}

img.shot {
    transform: rotate(-1deg);
    width: 200px;
    height: 200px;
    border-radius: 50%;
    opacity: 1;
    box-shadow: 5px 5px 15px rgba(0,0,0,.5);
    transition: opacity .4s ease-in;
}

img.squiggle {
    transform: rotate(-1deg);
    width: 200px;
    opacity: .4;
    transition: opacity .4s ease-in;
}


div {
    box-sizing: border-box;
}

.row {
    padding: 40px;
}

.flexy {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pic-v {
    height:400px;
    width: 225px;
    height:333px;
    width: 187px;
    transition: .1s ease-in;
    box-shadow: 15px 15px 25px rgba(0,0,0,.3);
}

a.menuitem {
    font-size: 125%;
    display: inline-block;
    width: 125px;
    text-align: center;
    padding: 15px;
    cursor: pointer;
    color: #ffe;
    text-decoration: none;
    transition: background .1s ease-in;
}

.menuitem-selected {
    font-weight: bold;
    background: rgba(255,255,255,.2);
}

.sticky-0 {
    position: sticky;
    top: 0;
    z-index: 1;
}

.stickynote {
    height: 250px;
    width: 250px;
    transform: rotate(-5deg);
    transition: opacity .6s ease-in;
    box-shadow: 15px 15px 25px rgba(0,0,0,.3);
    position: absolute;
    right: 50px;
    top: 30px;
    opacity: 1;
    z-index: 2;
}

.contactinput,
.contactinput.edge-autoSuggestFieldFilled,
.contactinput.edge-autofilled
{
    background: none !important;
    height: 250px !important;
    font-size: 1.5rem !important;
    padding: 10px !important;
    color: white !important;
    outline-width: 0 !important;
    border: 2px solid rgba(255,255,255,.1) !important;
    width: 100% !important;
    margin-bottom: 10px !important;
    box-sizing: border-box !important;
}

.contactinput[type='email'],
.contactinput[type='email'].edge-autoSuggestFieldFilled,
.contactinput[type='email'].edge-autofilled
{
    background: none !important;
    height: auto !important;
    width: 400px !important;
    max-width: 100% !important;
}

.contactinput[type='email']::placeholder {
    font-style: italic;
    color: #999;
    opacity: 1;
}

.contactinput[type='email']:focus::placeholder {
    color: transparent;
}

.contactinput:focus {
    border-color: rgba(175,205,255,.5);
}

.contactinput:empty:after {
    content: attr(placeholder);
    display: inline-block;
    color: #999;
    font-style: italic;
}

.contactinput:empty:focus:after {
    content: "";
    width:0;height:0;
    display: none;
}

input[type='email'] {
    background-color: red !important;
}

.contactbutton {
    background: #4f7e97;
    color: #ffe;
    font-size: 1.5rem;
    padding: 15px;
    float: right;
    margin: 20px 0;
    border: none;
    outline-width: 0;
    cursor: pointer;
    -webkit-appearance: none;
    border-radius: 0;
}

.contactbutton:disabled {
    background: #225e7d;
    opacity: .4;
    cursor: unset;
}

.flexy.topsection {
    height: 250px;
}

@media only screen and (max-width: 1050px) {
    .pic-v {
        width: calc(20vw);
        height: calc(36vw);
        width: calc(16vw);
        height: calc(28.8vw);
    }
}

@media only screen and (max-width: 850px) {
    .pic-v {
    }
}

@media only screen and (max-width: 770px) {
    .pic-v {
    }

    .stickynote {
        right: 20px;
        top: 20px;
        width: 200px;
        height: 200px;
    }
}

@media only screen and (max-width: 600px) {
    img.shot {
        width: 170px;
        height: 170px;
    }

    .row {
        padding: 25px;
    }

    a.menuitem {
        width: 100px;
        font-size: 100%;
    }

    .pic-v {
    }

    .flexy.topsection {
        height: 250px;
    }

    .stickynote {
        right: 20px;
        top: 20px;
        width: 170px;
        height: 170px;
    }
}

@media only screen and (max-width: 500px) {
    .pic-v {
    }

    .stickynote {
        width: 130px;
        height: 130px;
        right: 20px;
        top: 20px;
    }
    a.menuitem {
        width: 90px;
        font-size: 85%;
    }

}

@media only screen and (max-width: 420px) {

    a.menuitem {
        width: 70px;
        font-size: 75%;
    }
}

@media only screen and (max-width: 350px) {
}

.triangular {
    display: none;
}

h1, h2, h3, h4, .h1, .h2, .h3, .h4 {
    text-align: center;
}

.copyright {
    width: 100%;
    padding: 10px;
    //background: "#113845";
    color: #999999;
    text-align: center;
}

@page {
    margin: .5in;
}


.printonly {
    display: none;
}

.infographic {
    display: inline-block;
    opacity: .5;
    width: 128px;
    height: 128px;
    margin: 0 10px;
    background-color: #222;
    border-radius: 50%;
    padding: 10px;
    border: 2px solid #ccc;
}

@media print {
    .stickynote {
        width: 2in;
        height: 2in;
    }

    .infographic {
        display: none;
    }

    .printonly {
        display: block;
        padding-top: 20px;
    }

    .pic-v {
        height: 2.25in;
        width:  1.26in;
        height: 1.875in;
        width:  1.05in;
    }
    .row {
        padding: 10px 0 0 0;
    }

    h1 {
        padding-top: 10px;
        margin-top: 0;
    }

    #contact {
        display: none;
    }

    .section-menu {
        display: none;
    }

    .copyright {
        display: none;
    }

    .section .content {
        padding: 20px;
    }
}
